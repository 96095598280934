<template>
  <div class="annualFee">
<!--    <section class="search-option">-->
<!--      <span class="search_title active">专利名称</span>-->
<!--      <el-input class="_one" placeholder="专利名称"-->
<!--        v-model="searchParam.patentName"-->
<!--        clearable-->
<!--      />-->
<!--      <span style="margin-left: 20px" class="search_title">专利号</span>-->
<!--      <el-input placeholder="申请号"-->
<!--                class="_one"-->
<!--        v-model="searchParam.patentApplyNumber"-->
<!--        clearable-->
<!--      />-->
<!--      <span style="margin-left: 20px" class="search_title">法律状态</span>-->
<!--      <el-select-->
<!--        class="_one"-->
<!--        placeholder="请选择"-->
<!--        v-model="searchParam.patentLawStatus"-->
<!--        clearable-->
<!--      >-->
<!--        <el-option label="有效" value="1"/>-->
<!--        <el-option label="无效" value="0"/>-->
<!--      </el-select>-->

<!--      <span style="margin-left: 20px" class="search_title">年费缴纳时间</span>-->
<!--      <el-date-picker-->
<!--        v-model="searchParam.patentAnnualFeeTime"-->
<!--        value-format="yyyy-MM-dd HH:mm:ss"-->
<!--        type="daterange"-->
<!--        range-separator="至"-->
<!--        start-placeholder="开始日期"-->
<!--        end-placeholder="结束日期"-->
<!--      />-->
<!--      <div class="function">-->
<!--        <el-button style="width: 85px;border-radius: 10px;text-align: center" @click="searchParamReset">清空</el-button>-->
<!--        <el-button type="primary" style="margin-right: 59px;width: 85px;border-radius: 10px;text-align: center;margin-left: 20px" @click="doQueryPatentAnnualMonitorList">筛选</el-button>-->
<!--      </div>-->
<!--    </section>-->

    <div class="monitoring_table">
      <vxe-grid
        stripe
        :strip="true"
        header-row-class-name="vxe_grid_header"
        border="inner"
        height="770px"
        :highlight-current-row="true"
        :highlight-hover-row="true"
        :highlight-current-column="true"
        align="center"
        header-align="center"
        ref='xGrid'
        :cell-class-name="cellClassName"
        :row-class-name="tableRowClassNames"
        :row-style="rowStyle"
        v-bind="gridOptions"
        @clear-filter="filterChange"
        @filter-change="filterChange"
        @sort-change="filterChange"
        @cell-click="cellClick"
        @checkbox-change="isChecked"
        @checkbox-all="isCheckeds"
      >
        <!--将表单放在工具栏中-->
        <template #toolbar_buttons>
          <div class="monitoring_two">
            <div class="monitoring_two_btn">
              <div class="search_input">
                <el-input  size="small"  v-model="keyword" placeholder="请输入关键词搜索..."></el-input>
                <i @click="doQueryPatentAnnualMonitorList" style="color: #2D3748;font-size: 18px;margin-left: 5px" class="el-icon-search"></i>
              </div>
              <div class="function_btn">
                <div class="btn_1" @click="addPatentAnnualMonitor()">
                  <img src="../../assets/images/hostingPLatform/add.png" alt="">
                  添加监控
                </div>
                <div class="btn_2" @click="cancelPatentAnnualMonitor()">
                  取消监控
                </div>
                <div class="btn_3" @click="batchPayForPatentAnnual()">
                  <img src="../../assets/images/hostingPLatform/jiaofei.png" alt="">
                  一键代缴
                </div>
              </div>
            </div>
          </div>
        </template>

        <template #patentApplicationNo="{ row }">
          <span style="color: #1B82FC; cursor: pointer;" @click.stop="toDetail(row)">{{row.patentApplicationNo}}</span>
        </template>
        <template #applicationDate="{ row }">
          {{row.applicationDate}}
        </template>
        <template #annualFeeDate="{ row }">
          {{ifShowFee(row.annualFeeDate) && row.patentLawStatus == 1 ? row.annualFeeDate : ""}}
        </template>
        <template #patentYear="{ row }">
          {{row.patentLawStatus != 1 || row.patentYear == -1 ? "" : row.patentYear}}
        </template>
        <template #patentType="{ row }">
          {{row.patentType == 0 ? "发明" : row.patentType == 1 ? "实用新型" : "外观设计"}}
        </template>
        <template #patentLawStatus="{ row }">
          <span :class="[{'youxiao': row.patentLawStatus == 1, 'wuxiao': row.patentLawStatus != 1}]">{{row.patentLawStatus == 1 ? "有效" : "无效"}}</span>
        </template>
        <template #annualBasicFee="{ row }">
          {{ifShowFee(row.annualFeeDate) && row.patentLawStatus == 1 ? getPatentBasicFee(row) : ""}}
        </template>
        <template #annualLateFee="{ row }">
          {{ifShowFee(row.annualFeeDate) && row.patentLawStatus == 1 ? row.annualLateFee : ""}}
        </template>
        <template #agentFee="{ row }">
          {{ifShowFee(row.annualFeeDate) && row.patentLawStatus == 1 ? row.agentFee : ""}}
        </template>
        <template #annualFeeReduction="{ row }">
          {{ifShowFee(row.annualFeeDate) && row.patentLawStatus == 1 ? row.annualFeeReduction + '%' : ""}}
        </template>
        <template #annualFeeTotal="{ row }">
          {{ifShowFee(row.annualFeeDate) && row.patentLawStatus == 1 ? row.annualFeeTotal : ""}}
        </template>
        <template #patentAnnuityDetailList = "{row}">
          <el-table
            key="1"
            :fit="false"
            :show-header="false"
            ref='annuityDetailTable'
            max-height="300"
            :data="annuityDetailList"
            :row-style="detailRowStyle"
            header-cell-class-name="annuityDetailTableHeader"
            class="annuityDetailTable"
            @selection-change="handleAnnuityDetailTableSelectionChange"
          >
            <el-table-column
              type="selection"
              :selectable="selectable"
              align="center"
              width="50"
            />
            <el-table-column align="left" show-overflow-tooltip width="160" label="专利名称" prop="patentName"/>
            <el-table-column align="left" show-overflow-tooltip width="130" label="专利申请号" prop="patentApplicationNo"/>
            <el-table-column align="left" show-overflow-tooltip width="100" label="申请日期" prop="applicationDate"/>
            <el-table-column align="left" show-overflow-tooltip width="100" label="专利类型" prop="patentType">
              <template slot-scope="scope">
                {{row.patentType == 0 ? "发明" : row.patentType == 1 ? "实用新型" : "外观设计"}}
              </template>
            </el-table-column>
            <el-table-column align="left" show-overflow-tooltip width="100" label="法律状态" prop="patentLawStatus">
              <template slot-scope="scope">
                <span>
                   <span :class="[{'youxiao': row.patentLawStatus == 1, 'wuxiao': row.patentLawStatus != 1}]">{{row.patentLawStatus == 1 ? "有效" : "无效"}}</span>
                </span>
              </template>
            </el-table-column>
            <el-table-column align="left" show-overflow-tooltip width="100" label="专利年度" prop="patentYear"/>
            <el-table-column align="left" show-overflow-tooltip width="140" label="截止日期" prop="deadLine">
              <template slot-scope="scope">
                {{ scope.row.deadLine.substr(0, 10) }}
              </template>
            </el-table-column>
            <el-table-column align="left" show-overflow-tooltip width="120" label="专利年费" prop="annualBasicFee">
              <template slot-scope="scope">
              {{getPatentBasicFee(scope.row)}}
            </template>
            </el-table-column>
            <el-table-column align="left" show-overflow-tooltip width="80" label="滞纳金" prop="annualLateFee"/>
            <el-table-column align="left" show-overflow-tooltip width="80" label="代理费" prop="agentFee"/>
            <el-table-column align="left" show-overflow-tooltip width="80" label="专利费减" prop="annualFeeReduction">
              <template slot-scope="scope">
                {{ scope.row.annualFeeReduction == 0 ? "" : scope.row.annualFeeReduction+"%" }}
              </template>
            </el-table-column>
            <el-table-column align="left" show-overflow-tooltip width="100" label="费用总计" prop="annualFeeTotal"/>

<!--            <el-table-column label="年费类型" prop="annuityName"/>-->

<!--            <el-table-column label="专利年费" align="center" prop="annualBasicFee"/>-->
<!--            <el-table-column label="滞纳金" align="center" prop="annualLateFee"/>-->
<!--            <el-table-column label="权利恢复费" align="center" prop="patentRestorationFee"/>-->
<!--            <el-table-column label="代理费" align="center" prop="agentFee"/>-->
<!--            <el-table-column label="专利费减" align="center" prop="annualFeeReduction">-->
<!--              <template slot-scope="scope">-->
<!--                {{ scope.row.annualFeeReduction == 0 ? "" : scope.row.annualFeeReduction+"%" }}-->
<!--              </template>-->
<!--            </el-table-column>-->

<!--            <el-table-column label="费用总计" align="center" prop="annualFeeTotal"/>-->
          </el-table>
        </template>
        <template #operation="{ row }">
          <div class="operation" >
            <div v-if="row.annualFeeTotal != 0 && row.annualFeeTotal != null && row.patentYear !=21 && row.patentLawStatus == 1"
                 @click="payForPatentAnnual(row)">
              委托代缴
            </div>
            <div v-if="row.annualFeeTotal != 0 && row.annualFeeTotal != null && row.patentYear !=21 && row.patentLawStatus == 1"
                 @click="patentAnnualFeeHasBeenPaid(row)" >
              年费已缴
            </div>
          </div>
        </template>
        <template #pager>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 15, 20, 50, 100]"
            layout="total, sizes, prev, pager, next"
            :current-page.sync="searchParam.current"
            :page-size.sync="searchParam.size"
            :total="total">
          </el-pagination>
        </template>
      </vxe-grid>
    </div>


    <el-dialog width="720px" :visible.sync="patentAnnuityAddDialogShow" class="softness_dialog">
      <div style="display: flex;align-items: center" slot="title">
        <img src="../../assets/images/hostingPLatform/tips.png" alt="">
        <span style="margin-left: 11px;font-size: 16px;font-weight: 500;color: #444444;">请选择您要添加的数据</span>
      </div>
<!--      <span class="monitor_title">{{applicantName}}</span>-->
      <el-select @change="selectApplican" v-model="applicantName" placeholder="请选择">
        <el-option
          v-for="item in applicantNameList"
          :key="item.applicantName"
          :label="item.applicantName"
          :value="item.applicantName">
        </el-option>
      </el-select>
      <div class="monitor_clearfix">
        <span class="table_title">专利</span>
        <el-table
          height="300"
          :row-class-name="noEnable"
          :header-cell-style="{background: '#D3DBF8'}"
          style="width: 622px"
          ref='annuityAddTable'
          :row-key="getRowKeys"
          :data="summaryData.patentPlatformPage && summaryData.patentPlatformPage.records"
          tooltip-effect="dark"
          @selection-change="handleAddTableSelectionChange">
          <el-table-column
            :reserve-selection="true"
            type="selection"
            align="center"
            width="55"
            :selectable="getRowSelectable"
          />
          <el-table-column  show-overflow-tooltip label="专利名称" prop="patentName"/>
          <el-table-column width="140" label="专利申请号" prop="patentApplicationNo"/>
          <el-table-column width="100" label="申请日期" prop="applicationDate">
            <template slot-scope="scope">
              {{ scope.row.applicationDate }}
            </template>
          </el-table-column>
          <el-table-column width="100" label="专利类型" prop="patentType">
            <template slot-scope="scope">
              {{ scope.row.patentType == 0 ? "发明" : scope.row.patentType == 1 ? "实用新型" : "外观设计" }}
            </template>
          </el-table-column>
          <el-table-column width="100" label="法律状态" prop="patentLawStatus">
            <template slot-scope="scope">
              {{ scope.row.patentLawStatus == 1 ? "有效" : "无效" }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          style="margin-top: 10px"
          @current-change="handleCurrentChanges"
          :current-page.sync="tableConfig.current"
          :page-size="tableConfig.size"
          layout="total, prev, pager, next"
          :total="summaryData.patentPlatformPage && summaryData.patentPlatformPage.total">
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
           <el-button  @click="doAddPatentAnnuity">保存</el-button>
        </span>
    </el-dialog>
    <el-dialog
      :visible.sync="patentAnnuityCancelMonitorDialogShow"
      class="patentAnnuityMessageDialog"
      title="提示"
    >
      <p>您确定取消选中的<span style="color: red">{{annuityCheckedList.length}}</span>条专利的年费监控嘛？</p>
      <el-row class="patentAnnuityMessageDialogButton">
        <el-button @click="doCancelMonitor" type="primary" size="small">确定</el-button>
        <el-button @click="closeDialog" size="small">取消</el-button>
      </el-row>
    </el-dialog>
    <el-dialog
      :visible.sync="patentAnnualFeeHasBeenPaidDialogShow"
      class="patentAnnuityMessageDialog"
      title="提示"
    >
      <p>您确定本条监控已经缴纳过费用了吗？</p>
      <el-row class="patentAnnuityMessageDialogButton">
        <el-button @click="doPatentAnnualFeeHasBeenPaid" type="primary" size="small">确定</el-button>
        <el-button @click="closeDialog" size="small">取消</el-button>
      </el-row>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisibles" :close-on-click-modal="false" custom-class="login_dialog" :before-close="closeEvent">
      <login @loginDialog="loginDialog" :dialog-close="dialogClose" @dialogCloseBool="dialogCloseBool"/>
    </el-dialog>
  </div>
</template>

<script>

import moment from "moment";

  export default {
    data(){
      return{
        keyword: '',
        arr: [],
        copyId: '',
        objyeraList: [],
        current: 1,
        size: 50,
        //登录浮窗
        dialogVisibles: false,
        dialogClose: false,
        applicantName: '',
        applicantNameList: [],
        custodyTypeSet: 'patent',
        summaryData:[],
        multipleSelection:[],
        tableConfig:{
          size: 20,
          current: 1,
        },
        //搜索条件 包含顶部搜索条件和表格二次搜索
        searchParam:{
          current:1,
          size:20,

          patentName:null,
          patentApplyNumber:null,
          patentLawStatus:null,
          //前端使用 下面分开为start-end的后端使用
          patentApplicantTime:null,
          patentApplicantStartTime:null,
          patentApplicantEndTime:null,
          //前端使用 下面分开为start-end的后端使用
          patentAnnualFeeTime:null,
          patentAnnualFeeStartTime:null,
          patentAnnualFeeEndTime:null,

          secondSearchPatentType:null,
          secondSearchPatentYear:null,
          secondSearchPatentName:null,
          secondSearchPatentNumber:null,
          secondSearchPatentLawStatus:null,
          secondSearchPatentApplicantStartTime:null,
          secondSearchPatentApplicantEndTime:null,
          secondSearchPatentAnnualFeeStartTime:null,
          secondSearchPatentAnnualFeeEndTime:null,
        },
        total:0,
        totals:0,
        //表格配置项
        gridOptions:{
          id: 'annualFeeGrid',
          border: true,
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          height: 600,
          rowId: 'id',
          expandConfig: {
            accordion: true,
            lazy: true,
            reserve: false,
            loadMethod: this.loadMethod,
            toggleMethod: this.toggleMethod
          },
          filterConfig: {
            remote: true,
            iconNone: 'el-icon-search',
          },
          toolbarConfig: {
            slots: {
              buttons: 'toolbar_buttons'
            }
          },
          sortConfig: {
            trigger: 'cell',
            remote: true
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          data: [],
          Props: {
            stripe: true,
          },
          columns:[
            {
              type: 'checkbox',
              width: '50',
            },
            {
              align: 'left',
              field: 'patentName',
              title: '专利名称',
              width: 160,
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
              }
            },
            {
              align: 'left',
              field: 'patentApplicationNo',
              title: '专利申请号',
              width: 130,
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
              },
              slots: {
                default: 'patentApplicationNo'
              }
            },
            {
              align: 'left',
              field: 'applicationDate',
              title: '申请日期',
              width: '100',
              filters: [{data: null}],
              filterRender: {
                name: 'ElDatePicker',
                props:{
                  type:'daterange',
                  rangeSeparator:"至",
                  format:'yyyy-MM-dd',
                  valueFormat:'yyyy-MM-dd HH:mm:ss'
                }
              },
              slots: {
                default: 'applicationDate'
              }
            },
            {
              align: 'left',
              field: 'patentType',
              title: '专利类型',
              width: '100',
              filters: [
                {label:"发明",value: "0"},
                {label:"实用新型",value: "1"},
                {label:"外观设计",value: "2"}
              ],
              slots: {
                default: 'patentType'
              }
            },
            {
              align: 'left',
              field: 'patentLawStatus',
              title: '法律状态',
              width: '100',
              filterMultiple:false,
              filters: [
                {label:"有效",value: true},
                {label:"无效",value: false}
              ],
              slots: {
                default: 'patentLawStatus'
              }
            },
            {
              align: 'left',
              field: 'patentYear',
              title: '专利年度',
              width: '100',
              filters: [{data: null}],
              filterRender: {
                name: 'ElInput',
              },
              slots: {
                default: 'patentYear'
              }
            },
            {
              align: 'left',
              field: 'annualFeeDate',
              title: '年费缴纳时间',
              width: '140',
              filters: [{data: null}],
              filterRender: {
                name: 'ElDatePicker',
                props:{
                  type:'daterange',
                  rangeSeparator:"至",
                  format:'yyyy-MM-dd',
                  valueFormat:'yyyy-MM-dd HH:mm:ss'
                }
              },
              slots: {
                default: 'annualFeeDate'
              }
            },
            {
              align: 'left',
              field: 'annualBasicFee',
              title: '专利年费',
              width: '120',
              slots: {
                default: 'annualBasicFee'
              }
            },
            {
              align: 'left',
              field: 'annualLateFee',
              title: '滞纳金',
              width: '80',
              slots: {
                default: 'annualLateFee'
              }
            },
            {
              align: 'left',
              field: 'agentFee',
              title: '代理费',
              width: '80',
              slots: {
                default: 'agentFee'
              }
            },
            {
              align: 'left',
              field: 'annualFeeReduction',
              title: '年费减免',
              width: '80',
              slots: {
                default: 'annualFeeReduction'
              }
            },
            {
              align: 'left',
              field: 'annualFeeTotal',
              title: '年费总计',
              width: '100',
              slots: {
                default: 'annualFeeTotal'
              }
            },
            {
              align: 'left',
              field: 'patentOperate',
              fixed: "right",
              title: '操作',
              visible: true,
              width: "200px",
              slots: {
                default: 'operation'
              }
            },
            {
              type: 'expand',
              visible: false,
              slots: {
                content:'patentAnnuityDetailList'
              }
            }
          ],
          checkboxConfig: {
            reserve: true,
            highlight: true,
            range: true
          },
        },
        selectedRows: [],
        annuityCheckedList:[],
        //表格拓展数据
        annuityDetailList:[],
        annuityDetailCheckedList:[],
        //是否展示添加专利年费
        patentAnnuityAddDialogShow:false,
        patentAnnuityAddData:[],
        selectedAddList:[],
        //是否显示取消监控
        patentAnnuityCancelMonitorDialogShow:false,
        //是否显示已经缴纳
        patentAnnualFeeHasBeenPaidDialogShow:false,
        patentAnnualFeeHasBeenPaidId : 0
      }
    },
    async mounted() {
      // this.applicantName = localStorage.getItem('applicant')
      await this.pageInit()
      this.editeApplicantName()
    },
    watch: {
      patentAnnuityAddDialogShow(newVal){
        if(newVal){
          this.multipleSelection = []
          this.$refs.annuityAddTable.clearSelection();
        }
      }
    },
    methods:{
      selectApplican(e){
        this.addPatentAnnualMonitor()
      },
      getPatentBasicFee(row){
        switch (row.patentType){
          case 0:
            //发明专利
            switch (row.patentYear){
              case 1:
              case 2:
              case 3:
                return 900
              case 4:
              case 5:
              case 6:
                return 1200
              case 7:
              case 8:
              case 9:
                return 2000
              case 10:
              case 11:
              case 12:
                return 4000
              case 13:
              case 14:
              case 15:
                return 6000
              case 16:
              case 17:
              case 18:
              case 19:
              case 20:
                return 8000
              default:
                return ""
            }
          case 1:
          case 2:
            //实用新型专利、外观设计专利
            switch (row.patentYear){
              case 1:
              case 2:
              case 3:
                return 600
              case 4:
              case 5:
                return 900
              case 6:
              case 7:
              case 8:
                return 1200
              case 9:
              case 10:
                return 2000
              default:
                return ""
            }
          default:
            return ""
        }
      },
      tableRowClassNames({row, rowIndex}) {
        if(row.patentLawStatus != 1){
          return 'color-row1-1';
        }
      },
      totalqq(){
        this.$nextTick(()=>{
          this.$refs.annuityDetailTable.clearSelection()

        })

      },
      totalqqs(){
          this.$nextTick(() => {
            if(this.arr.filter(item => item.id == this.copyId).length != 0){
              this.arr.filter(item => item.id == this.copyId)[0].annuityYearSet.forEach(items => {
                this.$refs.annuityDetailTable.toggleRowSelection(this.annuityDetailList.filter(item => item.patentYear == items)[0]);
              });
            }
          })
      },
      noEnable({row, rowIndex}){
        if(row.existFlag) return 'existFlag'
      },
      //页面初始化函数
      async pageInit(){
        //若已经登录，则获取监控列表
        if (this.tool.getCookie("auth")) {
          await this.doQueryPatentAnnualMonitorList()
        }
        //若未登录，则跳出登录框
        else {
          this.dialogVisibles = true
        }
      },
      //获取当前用户监控的申请人名称
      editeApplicantName(){
        this.$axios.get('/custody/applicant/list')
          .then(({data}) => {
          if(data.code == 0){
            this.applicantNameList = data.data.filter(item => item.storeFlag)

            this.applicantName = this.applicantNameList[0].applicantName
          }
        })
      },
      //实际执行查询专利年费列表
      doQueryPatentAnnualMonitorList(){
        if(this.searchParam.patentApplicantTime != null && this.searchParam.patentApplicantTime.length > 0){
          this.searchParam.patentApplicantStartTime = this.searchParam.patentApplicantTime[0]
          this.searchParam.patentApplicantEndTime = this.searchParam.patentApplicantTime[1]
        }

        if(this.searchParam.patentAnnualFeeTime != null && this.searchParam.patentAnnualFeeTime.length > 0){
          this.searchParam.patentAnnualFeeStartTime = this.searchParam.patentAnnualFeeTime[0]
          this.searchParam.patentAnnualFeeEndTime = this.searchParam.patentAnnualFeeTime[1]
        }

        this.$axios.post("/custody/patent/annuity/page", {...this.searchParam, keyword: this.keyword})
          .then(response=>{
            this.gridOptions.data = response.data.data.records
            this.total = response.data.data.total
          })
      },
      //清空
      searchParamReset(){
        this.searchParam.current = 1
        this.searchParam.patentName = null
        this.searchParam.patentApplyNumber = null
        this.searchParam.patentLawStatus = null
        this.searchParam.patentApplicantTime = null
        this.searchParam.patentApplicantStartTime = null
        this.searchParam.patentApplicantEndTime = null
        this.searchParam.patentAnnualFeeTime = null
        this.searchParam.patentAnnualFeeStartTime = null
        this.searchParam.patentAnnualFeeEndTime = null
        this.doQueryPatentAnnualMonitorList()
      },
      //设定表格cell属性
      cellClassName({row, column, $columnIndex}) {
        if(row.annualFeeDate != null && $columnIndex == 8 && this.getDueThisMonth(row.annualFeeDate)){
          return 'textRed'
        }
      },
      getDueThisMonth(annualFeeDate){
        let annualFeeYear = Number(annualFeeDate.substr(0, 4))
        let nowYear = new Date().getFullYear()

        let annualFeeMonth = Number(annualFeeDate.substr(5, 2))
        let nowMonth = new Date().getMonth()+1

        return  (annualFeeMonth == nowMonth) && (nowYear == annualFeeYear)
      },
      //设置表格行属性
      rowStyle({row, rowIndex, $rowIndex}) {
        //本月应该缴纳的年费数据修改样式
        // if(
        //   moment(row.annualFeeDate).subtract(1, "months").isBefore(moment.now())
        // ){
        //   return 'color:red;'
        // }
      },
      detailRowStyle({row, rowIndex}){
        // if(
        //   moment(row.deadLine).subtract(1, "months").isBefore(moment.now())
        // ){
        //   console.log(123)
        //   return {
        //     "color":"red"
        //   }
        // }
      },
      ifShowFee(annualFeeDate){
        return true
        // if(annualFeeDate != null ){
        //   let startTime = moment(annualFeeDate).subtract(3, 'months')
        //   let endTime = moment(annualFeeDate).add(6, 'months')
        //
        //   return moment(startTime).isBefore(moment.now()) && moment(endTime).isAfter(moment.now())
        // }
        // return false
      },
      //获取表格二次筛选属性
      filterChange() {
        this.searchParam.secondSearchPatentName = null
        this.searchParam.secondSearchPatentNumber = null
        this.searchParam.secondSearchPatentYear = null
        this.searchParam.secondSearchPatentType = null
        this.searchParam.secondSearchPatentLawStatus = null
        this.searchParam.secondSearchPatentApplicantStartTime = null
        this.searchParam.secondSearchPatentApplicantEndTime = null
        this.searchParam.secondSearchPatentAnnualFeeStartTime = null
        this.searchParam.secondSearchPatentAnnualFeeEndTime = null

        let getCheckedFilters = this.$refs.xGrid.getCheckedFilters()

        getCheckedFilters.map(item=>{
          switch (item.property){
            case "patentName":
              this.searchParam.secondSearchPatentName = item.datas[0].trim()
              break
            case "patentApplicationNo":
              this.searchParam.secondSearchPatentNumber = item.datas[0].trim()
              break
            case "patentYear":
              this.searchParam.secondSearchPatentYear = item.datas[0].trim()
              break
            case "patentType":
              this.searchParam.secondSearchPatentType = item.values.join(",")
              break
            case "patentLawStatus":
              this.searchParam.secondSearchPatentLawStatus = item.values[0]
              break
            case "applicationDate":
              this.searchParam.secondSearchPatentApplicantStartTime = item.datas[0][0]
              this.searchParam.secondSearchPatentApplicantEndTime = item.datas[0][1]
              break
            case "annualFeeDate":
              this.searchParam.secondSearchPatentAnnualFeeStartTime = item.datas[0][0]
              this.searchParam.secondSearchPatentAnnualFeeEndTime = item.datas[0][1]
              break
          }
        })

        this.doQueryPatentAnnualMonitorList()
      },
      cellClick({
                  row,
                  rowIndex,
                  $rowIndex,
                  column,
                  columnIndex,
                  $columnIndex,
                  triggerRadio,
                  triggerCheckbox,
                  triggerTreeNode,
                  triggerExpandNode,
                  $event
                }){
        if($columnIndex != 0 && column.field != "patentOperate"){
          this.$refs.xGrid.toggleRowExpand(row)
        }
      },
      isChecked(value){
        this.annuityCheckedList = [...value.records, ...value.reserves]
      },
      isCheckeds(value){
        this.annuityCheckedList = [...value.records, ...value.reserves]
      },

      handleSizeChange(pageSize) {
        this.searchParam.size = pageSize
        this.doQueryPatentAnnualMonitorList()
      },
      //表格页码变化触发
      handleCurrentChange(currentPage) {
        this.searchParam.current = currentPage
        this.doQueryPatentAnnualMonitorList()
      },
      loadMethod({row}){
        this.copyId = row.id
        return new Promise(resolve => {
          this.$axios.get("/custody/patent/query/annuity/"+row.patentApplicationNo)
            .then(response=>{
              this.annuityDetailList = response.data.data.map((item, index) =>{
                item.id = index
                return {...row,...item}
              })
              // this.$refs.annuityDetailTable.clearSelection()


              this.$refs.xGrid.setRowExpand(row, true)
            })

        })
      },
      toggleMethod({expanded, column, columnIndex, row, rowIndex}) {
        if (expanded == true){
          this.$refs.xGrid.clearRowExpandLoaded(row)
        }
        else {
          this.annuityDetailCheckedList = []
        }
        return true
      },
      selectable(row, index) {
        // 如果没有选择任何行，第一行可以勾选
        if (this.annuityDetailCheckedList.length === 0) {
          return index === 0;
        }
        // 只有前一行被选中时，当前行才可选
        return index <= this.annuityDetailCheckedList.length;
      },
      handleAnnuityDetailTableSelectionChange(selection){
        if(selection.length == 0){
          this.$refs.xGrid.setCheckboxRow(this.gridOptions.data.filter(item => item.id == this.copyId)[0], false)
        }else {
          this.$refs.xGrid.setCheckboxRow(this.gridOptions.data.filter(item => item.id == this.copyId)[0], true)
        }

        if(this.arr.filter(item => item.id == this.copyId).length == 0){
          this.arr.push({
            id: this.copyId,
            annuityYearSet: selection.map(item => item.patentYear)
          })
        }else {
          this.arr.map(item => {
            if(item.id == this.copyId){
              item.annuityYearSet = selection.map(item => item.patentYear)
            }
          })
        }
        // this.gridOptions.filter(item => item.patentApplyNo)
        // if(selection.length == 0) this.$refs.xGrid.setCheckboxRow(rows, checked)
        // else this.$refs.xGrid.setCheckboxRow(rows, checked)

        this.annuityDetailCheckedList = selection
      },
      toDetail(row){
        this.$axios.get("/custody/patent/query/detail/"+row.patentApplicationNo)
          .then(response=>{
            let url = response.data.data.patentAnnex
            // 带有查询参数的路由
            const routeData = this.$router.resolve({
              name: 'annualFeeDetail',
              query: { url: url }
            });
            // 打开新页面
            window.open(routeData.href, '_blank');
          })
      },
      //一键代缴 arr.slice(0, arr.length - 1)
      batchPayForPatentAnnual(){
        let arrs = []
        let annuityCheckedList = this.$refs.xGrid.getCheckboxRecords()
        annuityCheckedList.map(item => {
          if(this.arr.filter(items => items.id == item.id).length != 0){
            if(this.arr.filter(items => items.id == item.id)[0].annuityYearSet.length == 0){
              this.arr.filter(items => items.id == item.id)[0].annuityYearSet = [item.patentYear]
            }
          }else {
            this.arr.push({
              id: item.id,
              annuityYearSet: [item.patentYear]
            })
          }
        })
        if(this.arr.map(item => {
          if(annuityCheckedList.filter(items => items.id == item.id).length == 0){
          }else {
            arrs.push(item)
          }
        }))
        if(arrs.length == 0){
          this.$message("请至少选择一条数据")
          return
        }

        this.$axios.post("/custody/annuity/order/create/platform/order/number",  arrs)
          .then(({data})=>{
            if(data.code == 0){
              this.$router.push({
                path: '/hostingPlatforms/platformPay',
                query: {
                  order: 'order',
                  renewalOrderNumber: data.data,
                  type: 'patent'
                }
              })
            }
          })
      },
      //委托代缴
      payForPatentAnnual(row){
        let annuityDetailDtoList = []
        if(this.arr.filter(items => items.id == row.id).length == 0){
          annuityDetailDtoList =[ {
            id: row.id,
            annuityYearSet:[row.patentYear]
          }]
        }else {
          annuityDetailDtoList =[ {
            id: row.id,
            annuityYearSet: this.arr.filter(items => items.id == row.id)[0].annuityYearSet.length == 0 ? [row.patentYear] : this.arr.filter(items => items.id == row.id)[0].annuityYearSet
          }]
        }


        this.$axios.post("/custody/annuity/order/create/platform/order/number", annuityDetailDtoList)
          .then(({data})=>{
            if(data.code == 0){
              this.$router.push({
                path: '/hostingPlatforms/platformPay',
                query: {
                  order: 'order',
                  renewalOrderNumber: data.data,
                  type: 'patent'
                }
              })
            }
          })
      },
      //年费已缴
      patentAnnualFeeHasBeenPaid(row){
        this.$confirm('请您确认该专利的年费是否已经缴纳，若确认无误，我们将开启该专利下一年的监控', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.patentAnnualFeeHasBeenPaidId = row.id
          let patentIdList = []
          patentIdList.push(this.patentAnnualFeeHasBeenPaidId)

          this.$axios.post("/custody/patent/annuity/annuityAlreadyPaid", patentIdList)
            .then(response=>{
              this.doQueryPatentAnnualMonitorList()
              this.patentAnnualFeeHasBeenPaidId = 0
              this.closeDialog()
              this.$message.success("数据更新成功")
            })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消年费已缴'
          });
        });
      },
      doPatentAnnualFeeHasBeenPaid(){

      },
      //取消管理
      cancelPatentAnnualMonitor(){
        if(this.annuityCheckedList.length == 0){
          this.$message("请至少选择一条数据")
          return
        }
        // this.patentAnnuityCancelMonitorDialogShow = true
        this.doCancelMonitor()
      },
      //执行取消监控
      doCancelMonitor(){
        let patentAnnuityId = []
        this.annuityCheckedList.forEach(item=>{
          patentAnnuityId.push(item.id)
        })

        this.$axios.post("/custody/patent/annuity/cancelMonitor", patentAnnuityId)
          .then(response=>{
            this.doQueryPatentAnnualMonitorList()
            this.closeDialog()
          })
      },
      //表格页码变化触发
      handleCurrentChanges(currentPage) {
        this.tableConfig.current = currentPage
        //获取列表数据
        this.addPatentAnnualMonitor()
      },
      //添加专利年费监控
      addPatentAnnualMonitor(){
        this.$axios
          .post("/custody/home/applicant/info/summary", {
            applicantNameSet: [this.applicantName],
            custodyTypeSet: [this.custodyTypeSet],
            current: this.tableConfig.current,
            size: this.tableConfig.size
          },{timeout: 60 * 1000 * 5}).then(({data}) => {
          if(data.code == 0){
            this.summaryData = data.data[0]
            this.$refs.annuityAddTable.clearSelection()
          }
        })

        // this.$axios.post("/custody/patent/query/annuity/addList",searchParam)
        //   .then(response=>{
        //     this.patentAnnuityAddData = response.data[0].patentPlatformPage.records
        //     this.totals = response.data[0].patentPlatformPage.total
        //     this.$refs.annuityAddTable.clearSelection()
        //   })
        this.patentAnnuityAddDialogShow = true
      },
      handleAddTableSelectionChange(selection){
        this.selectedAddList = selection
      },
      doAddPatentAnnuity(){
        this.multipleSelection.push({
          applicantName: this.summaryData.applicantName,
          custodyTypeSet: [],
          patentPlatformList: this.selectedAddList
        })

        this.multipleSelection.map(item => {
          if(item.patentPlatformList) item.custodyTypeSet.push('patent')
          if(item.trademarkRenewalPlatformList) item.custodyTypeSet.push('trademark')
          if(item.softwarePlatformList) item.custodyTypeSet.push('software')
          if(item.certificatePlatformList) item.custodyTypeSet.push('certificate')
          return item
        })

        this.multipleSelection = this.multipleSelection.filter(item => item.patentPlatformList || item.trademarkRenewalPlatformList || item.softwarePlatformList || item.certificatePlatformList)

        if(this.multipleSelection.length == 0){
          this.$message.error('请选择要保存的知识产权托管数据');
          return;
        }
        this.$axios
          .post("/custody/home/save/update/custody", this.multipleSelection[0]).then(({data}) => {
          if(data.code == 0){
            this.$message({
              message: data.data,
              type: 'success'
            });
            // this.monitor = false
            this.current = 1
            this.doQueryPatentAnnualMonitorList()
            this.closeDialog()
          }else {
            this.$message.error('知识产权托管数据保存失败');
          }
        }).catch(err => {
          this.$message.error(err.data.msg);
        })
      },
      // doAddPatentAnnuity(){
      //
      //   this.$axios.post("/custody/patent/annuity/save", this.selectedAddList).then(response=>{
      //
      //   })
      // },
      getRowKeys(row) {
        return row.id
      },
      getPatentAnnuityAddFormRowClassName({row, index}){
        return row.existFlag == true ? "rowGreyStyle" : ""
      },
      getRowSelectable(row){
        return !row.existFlag
      },
      //关闭浮窗
      closeDialog(){
        this.patentAnnuityAddDialogShow = false
        this.patentAnnuityCancelMonitorDialogShow = false
        this.patentAnnualFeeHasBeenPaidDialogShow = false
      },

      // 登录弹层隐藏
      loginDialog(data) { this.dialogVisibles = data },
      dialogCloseBool(data) { this.dialogClose = data },
      closeEvent(done) {
        this.dialogClose = true
        done()
      },
    }
  }
</script>

<style lang="less">
  .annualFee {
    /deep/ .vxe-table--body-wrapper::-webkit-scrollbar {
      width: 10px; // 横向滚动条
      height: 10px; // 纵向滚动条 必写
    }

    // 滚动条的滑块
    /deep/ .vxe-table--body-wrapper::-webkit-scrollbar-thumb {
      background: hsla(0, 0%, 53%, .1);
      border-radius: 10px
    }

    /deep/ .vxe-table--body-wrapper::-webkit-scrollbar-track {
      background: hsla(0, 0%, 53%, .1)
    }

    .sortable-column-demo .vxe-header--row .vxe-header--column.sortable-ghost,
    .sortable-column-demo .vxe-header--row .vxe-header--column.sortable-chosen {
      background-color: #dfecfb;
    }

    .sortable-column-demo .vxe-header--row .vxe-header--column.col--fixed {
      cursor: no-drop;
    }

    .vxe-body--row.is--expand-row.row--current {
      background: #EBF0FA!important;
    }

    .el-table__body-wrapper.is-scrolling-left {
      background: #E8E9ED!important;
    }
    .el-table__body-wrapper.is-scrolling-right  {
      background: #E8E9ED!important;
    }

    .vxe-cell--checkbox {
      .vxe-checkbox--icon {
        font-size: 16px;
        width: 17px;
        height: 16px;
        color: #ffffff;
        background: #ffffff;
        border-radius: 4px; /* 圆角 */
        border: none!important;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2) ; /* 阴影效果 */
      }
    }
    /* 自定义 VXE-Table 复选框的阴影效果 */
    .vxe-cell--checkbox.is--checked {
      .vxe-checkbox--icon {
        /*border: 1px solid #dcdfe6!important; !* 边框颜色 *!*/
        &.vxe-icon-checkbox-checked {
          width: 18px;
          height: 17px;
          box-shadow: none !important; /* 阴影效果 */
          color: #2264E5!important;
        }
      }
    }

    .vxe-grid--pager-wrapper {
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background: rgba(244,247,252,0.75);
      border-radius: 8px 8px 8px 8px;
      margin-top: 10px;
      text-align: right;
      ul {
        background: rgba(244,247,252,0.75);
      }
      button {
        background: rgba(244,247,252,0.75);
      }
      .el-input__inner {
        background: rgba(244,247,252,0.75);
        border: none;
      }
    }

    .vxe-body--row {
      height: 64px;
      transition: all 0.2s ease-in;
      &.row--strip {
        background: #F9FAFC!important;
      }
    }

    .vxe-table--header {
      height: 70px;
      border-radius: 15px 15px 0px 0px;
      overflow: hidden;
    }

    .color-row1-1{
      background: #F5F7FA;
    }
    tbody {
      .vxe-cell {
        font-weight: 500;
        font-size: 12px;
        color: #464F60;
        .trademarkImageUrl {
          display: flex;
          align-items: center;
          background: #FFFFFF;
          box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.12);
          border-radius: 8px!important;
          padding: 8px 31px!important;
          box-sizing: border-box;
        }

        .youxiao {
          width: 52px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #EAECFF;
          border-radius: 20px;
          font-weight: 400;
          font-size: 12px;
          color: #4F5AED;
        }

        .wuxiao {
          width: 52px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #FAF0F3;
          border-radius: 20px;
          font-weight: 400;
          font-size: 12px;
          color: #D12953;
        }
      }
      .vxe-body--expanded-row {
        background: #E8E9ED!important;
      }
      .el-table__body-wrapper.is-scrolling-none {
        background: #E8E9ED!important;
      }

      .vxe-body--expanded-cell {
        .el-table tr {
          height: 49px;
          td {
            font-weight: 400!important;
            font-size: 12px!important;
            color: #464F60!important;
          }
          background: #E8E9ED!important;
          .el-table__body-wrapper .el-table__row:hover {
            background: #E8E9ED!important;
          }
          /* 如果表格内容滚动时要改变背景色 */
          .el-table__body-wrapper::-webkit-scrollbar-thumb {
            background: #E8E9ED!important;
          }
          .youxiao {
            width: 52px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #EAECFF;
            border-radius: 20px;
            font-weight: 400;
            font-size: 12px;
            color: #4F5AED;
          }

          .wuxiao {
            width: 52px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #FAF0F3;
            border-radius: 20px;
            font-weight: 400;
            font-size: 12px;
            color: #D12953;
          }
        }
      }
    }


    .vxe_grid_header {
      font-weight: 500;
      font-size: 14px;
      color: #464F60;
      border-radius: 5px 5px 0px 0px !important;
      overflow: hidden !important;
      background: rgba(244,247,252,0.75) !important;
    }

    .row--checked,.row--hover {
      background: #EBF0FA !important;
      -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
      -webkit-transform: translate3d(0, 0px, -2px);
      transform: translate3d(0, 1px, -2px);
      .col--checkbox {
        position: relative;
        &:before {
          width: 2px;
          height: 64px;
          background: #2264E5;
          border-radius: 0px 0px 0px 0px;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .youxiao {
        width: 52px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff!important;
        border-radius: 20px;
        font-weight: 400;
        font-size: 12px;
        color: #4F5AED;
      }

      .wuxiao {
        width: 52px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff!important;
        border-radius: 20px;
        font-weight: 400;
        font-size: 12px;
        color: #D12953;
      }
    }

    .vxe-body--row {
      transition: all 0.2s ease-in;
      &.row--strip {
        background: #F9FAFC!important;
      }
      .operation {
        display: flex;
        justify-content: space-between;
        div {
          width: 78px;
          height: 29px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:nth-of-type(2) {
            background: #F2F6FF;
            border-radius: 12px 12px 12px 12px;
            border: 1px solid #3B5789;
            font-weight: 400;
            font-size: 12px;
            color: #2555A2;
          }
          &:nth-of-type(1) {
            background: #2264E5;
            border-radius: 15px 15px 15px 15px;
            font-weight: 400;
            font-size: 12px;
            color: #FFFFFF;
          }
        }
      }
    }

    .col-orange div {
      width: 118px !important;
      display: flex !important;
      height: 48px !important;
      padding: 2px 0 !important;
      justify-content: center !important;
    }
  }
</style>
<style scoped lang="less">
  .el-select-dropdown__item {
    height: auto !important;
  }

  .el-tree {
    display: flex;
    flex-direction: column;
  }

  section.search-option {
    min-height: 100px;
    background-color: #ffffff;
    border-radius: 8px;
    box-sizing: border-box;
    /*border: solid 1px #e4e4e4;*/
    margin-bottom: 20px;
    padding-top: 23px;
    padding-left: 19px;
    /*height: 135px;*/
    padding-bottom: 20px;
    .function{
      display: flex;
      justify-content: end;
    }
    .search_title {
      font-size: 14px;
      color: #474747;
      margin-right: 14px;
      &.active{
        position: relative;
        padding-left: 16px;
        &::before {
          position: absolute;
          content: '';
          width: 4px;
          height: 17px;
          background: #465CFC;
          left: 0;
          top: 2px;
        }
      }
    }

    div {
      margin-bottom: 10px;
      margin-right: 5px;
    }

    .el-select-long-officialt {
      width: 180px;
      height: 34px;
      border-radius: 3px;
      background: #ffffff;

      .el-input {
        input.el-input__inner {
          border: 1px solid #cccccc;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .one, .el-input {
      width: 180px;

      input {
        height: 34px !important;
        border-color: #ccc;
        color: inherit;
        padding: 0 4px;
      }
    }

    ._one /deep/ .el-input__inner {
      height: 34px;
    }

    .el-date-editor-long-officialt {
      width: 180px;
      height: 34px;
      border-radius: 3px;
      background: #ffffff;
      line-height: 32px;

      input.el-input__inner {
        border: 1px solid #cccccc;
        text-indent: 42px;
      }
    }
  }

  .annualFee {
    .danTitle {
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      color: #999999;
      font-weight: 600;
      position: relative;
      margin-left: 17px;

      &:before {
        position: absolute;
        content: '';
        width: 3px;
        height: 16px;
        top: 17px;
        background: #1a3dbf;
      }

      span {
        margin: 0 9px;

        &:nth-of-type(1) {
          margin-left: 18px;
        }

        &:nth-of-type(3) {
          color: #506bce;
        }
      }

      span:hover {
        cursor: pointer;
        color: #506bce;
      }
    }

    box-sizing: border-box;

    .monitoring_top {
      height: 131px;
      border: 1px solid #e4e4e4;
      background: #fff;
      margin: 15px auto;

      .condition {
        padding: 45px 0 0 45px;

        .el-input__inner {
          height: 36px;
        }

        .el-input {
          width: 220px;
          margin-right: 20px;
          padding: 0px;
          margin-left: 10px;
        }
      }
    }

    .monitoring_two {
      width: 100%;
      margin: auto;
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 9px;
      justify-content: space-between;
      .monitoring_two_btn{
        display: flex;
        width: 100%;
        justify-content: space-between;
        .search_input {
          display: flex;
          width: 296px;
          height: 45px;
          align-items: center;
          padding: 0 10px;
          background: #F7F7F8;
          border-radius: 15px 15px 15px 15px;
          border: 1px solid #E2E8F0;
          i {
            cursor: pointer;
          }
          /deep/input {
            background: #F7F7F8;
            border: none;
          }
        }
        .function_btn {
          display: flex;
          div {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .btn_1 {
            width: 112px;
            height: 32px;
            border-radius: 8px 8px 8px 8px;
            border: 1px solid #30396C;
            font-weight: 400;
            font-size: 12px;
            color: #30396C;
            margin-right: 19px;
            img {
              margin-right: 3px;
            }
          }

          .btn_2 {
            width: 86px;
            height: 32px;
            border-radius: 8px 8px 8px 8px;
            border: 1px solid #2555A2;
            font-weight: 400;
            font-size: 12px;
            color: #2555A2;
            margin-right: 19px;
          }

          .btn_3 {
            font-weight: 400;
            font-size: 12px;
            color: #FFFFFF;
            width: 115px;
            height: 32px;
            background: #2264E5;
            border-radius: 8px;
            img {
              margin-right: 3px;
            }
          }
        }
      }
      .totalMonitoring {
        background: #f4f7ff;
        font-size: 14px;
        color: #383838;
        width: 354px;
        height: 44px;
        border-radius: 20px;
        margin-right: 32px;
        display: flex;
        align-items: center;
        padding: 0 37px 0 33px;
        justify-content: space-between;
        box-sizing: border-box;

        i {
          font-size: 16px;
        }

        .active1 {
          color: #3057e3;
        }

        .active2 {
          color: #ff8d1a;
        }
      }

      .jg {
        width: 14px;
        cursor: pointer;
        position: absolute;
        margin-left: 0px;
        margin-right: 14px;
      }

      .recommend-tips {
        vertical-align: bottom;
        width: 425px;
        padding: 7px 12px;
        line-height: 17px;
        background: #fbedec;
        border: 1px solid #f6d4d4;
        font-size: 12px;
        text-align: left;

        &:before {
          content: "";
          width: 12px;
          height: 12px;
          position: absolute;
          border-bottom: 1px solid #f6d4d4;
          border-left: 1px solid #f6d4d4;
          top: 50%;
          left: -7px;
          transform: translateY(-50%) rotate(45deg);
          background: #fbedec;
        }
      }

      .addMsg {
        position: absolute;
        left: 110px;
        top: -18px;
      }

      .add {
        width: 152px;
        height: 32px;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 3px;
        text-align: center;
        line-height: 30px;
        color: #2c3e50;
        cursor: pointer;
        margin-top: 30px;
      }

      .jum {
        width: 120px;
        margin-left: 15px;
      }

      .add:hover {
        background: #193dbe;
        color: #fff;
        border: 1px solid #193dbe;
      }

      .batchImport {
        float: right;
        margin-top: 35px;

        span {
          margin-right: 15px;
          vertical-align: middle;

          img {
            vertical-align: middle;
          }

          .upload-demo {
            .el-upload-dragger {
              width: 100px;
              height: auto;
              background: none;
              border: none;
              margin-top: 3px;
            }

            .el-upload-list {
              display: none;
            }
          }
        }

        .icondaoru-tianchong {
          font-weight: bold;
          vertical-align: middle;
          color: #de5455;
          margin-right: 3px;
          font-size: 18px;
        }
      }
    }

    .monitoring_table {
      padding: 13px 19px;
      background: #FFFFFF;
      box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.08);
      border-radius: 15px 15px 15px 15px;
      /deep/.el-button--primary.is-plain {
        color: #ffffff;
      }
    }

    .seeResults:hover {
      text-decoration: underline;
    }

    .primary {
      background: #d01027;
      border: #d01027;
    }

    .primary:hover {
      background: #d73e3f;
    }

    .can:hover {
      color: white;
      background-color: #d01027;
      border: 1px solid #d01027;
    }

    .fyBlock {
      padding-bottom: 1%;
      padding-top: 2%;
      text-align: center;
    }

    .monitor {

      .prompt {
        height: 58px;
        line-height: 58px;
        border-bottom: 1px solid #e4e4e4;
        margin: 0 32px;
        text-align: center;

        .text {
          font-size: 14px;
          color: #222222;
          vertical-align: middle;
          margin-left: 10px;
        }
      }

      .mainBody {
        width: 495px;
        margin: 0px auto 0px;

        .check {
          margin-bottom: 20px;

          .requiredStar {
            color: #d01027;
            font-weight: bold;
            font-size: 16px;
          }

          .title:nth-child(1) {
            width: 105px;
            font-size: 14px;
            color: #222222;
            text-align: left;
            vertical-align: middle;
          }

          .title {
            display: inline-block;
          }

          .right {
            width: 380px;
            text-align: left;
            vertical-align: middle;
            position: relative;
            display: inline-block;

            .upload-drag {
              height: 82px !important;

              &.uploadDisabled {
                position: relative;

                .el-upload {
                  .el-upload-dragger {
                    .el-upload-disabled-shade {
                      display: block;
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      top: 0;
                      right: 0;
                      cursor: not-allowed;
                    }
                  }
                }
              }
            }

            .el-upload-dragger {
              border: 1px solid #e4e4e4;
              height: 82px;
              width: 380px;
              cursor: pointer;
            }

            .upload-img {
              height: 96%;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
            }

            p.delete {
              position: absolute;
              z-index: 5;
              right: 10px;
            }

            p {
              margin-top: 12px;
              font-size: 12px;
              letter-spacing: 1px;
              color: #999999;

              &.delete:hover {
                color: #0b6ce8;
              }
            }

            .leftText {
              height: 36px;
              border-color: #e4e4e4;
            }

            .el-input-long-officialt {
              width: 380px;
              background-color: #fff;

              &.active {
                width: 360px;
              }
            }

            .rightText {
              float: right;
              border-left: 1px solid #ccc;
              width: 40px;
              text-align: center;
            }

            .el-select-long-officialt {
              width: 380px;
              background-color: #fff;

              .el-input__suffix-inner {
                margin-top: 9px;
              }
            }

            .result {
              margin: 10px 0 0;

              .resultImg {
                width: 82px;
                height: 82px;
                border: 1px solid #e4e4e4;
                text-align: center;
                display: table-cell;
                vertical-align: middle;
                float: left;
              }

              .resultText {
                float: left;
                margin-left: 16px;

                span {
                  color: #222;
                  font-size: 14px;
                }
              }
            }
          }

          .niceCode {
            width: 380px;
            border: 1px solid #e4e4e4;
            height: 38px;
            border-radius: 3px;

            .leftText {
              border: transparent;
              width: 310px;

              input.el-input__inner {
                border: none !important;
              }
            }

            .rightText {
              width: 60px;
              height: 25px;
              margin-top: 9px;
            }
          }

          .el-select {
            .el-select__tags {
              .el-select__input {
                border: none !important;
              }
            }
          }
        }
      }

      .dialog-footer {
        float: right;
        margin-right: 113px;

        .submit {
          width: 90px;
          height: 36px;
          display: inline-block;
          background-color: #d01027;
          color: #fff;
          line-height: 36px;
          text-align: center;
          font-size: 14px;
          margin-top: 22px;
          margin-left: 32px;
          cursor: pointer;
          border-radius: 3px;
        }

        .submit:hover {
          background-color: #d73e3f;
        }
      }
    }
  }

  .softness_dialog{
    box-sizing: border-box;
    /deep/.el-dialog__body{
      padding: 0 22px;
      box-sizing: border-box;
    }
    /deep/.el-dialog{
      border-radius: 8px;
    }
    .monitor_title{
      font-size: 16px;
      font-weight: 500;
      color: #2E54D9;
      padding: 14px 0 0px 34px;
    }
    .monitor_clearfix{
      margin-top: 19px;
      width: 676px;
      height: 440px;
      background: #EFF2FD;
      border-radius: 12px;
      border: 1px solid #E1E7F2;
      padding: 16px 0 0 20px;
      box-sizing: border-box;
      .table_title{
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        position: relative;
        padding-left: 7px;
        &::before{
          position: absolute;
          width: 3px;
          height: 18px;
          background: #465CFC;
          content: '';
          left: 0;
          top: 4px;
        }
      }
      .el-table{
        margin-top: 14px;
        border-radius: 6px 6px 0px 0px;
        /deep/.existFlag td{
          background: #F5F7FA;
        }
      }
    }
  }
</style>
